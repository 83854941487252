import React, {
  Fragment,
  useEffect,
  useReducer,
  useState,
} from 'react';

import {
  Divider,
  Icon,
  message,
  Popover,
  Switch,
  Tag,
  Typography,
} from 'antd';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import { orderBy } from 'lodash';
import moment from 'moment';
import {
  Link,
  Route,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import {
  BooleanIcon,
  EditEmbassy,
  ExportXLSX,
  Filter,
  ToggableTable,
  ToggleColumns,
  ViewEmbassy,
} from '../components';
import { useSeason } from '../shared/hooks/useSeason';
import {
  formatDate,
  formatDatetimeBasic,
  isDatePastNow,
} from '../shared/utils/dateTime';
import { sorter } from '../shared/utils/sorter';

const getApplicationDocuments = () => {
  return [
    { id: "hasPhoto", title: "Снимка (5х5) + дигитална" },
    { id: "hasPassport", title: "Паспорт" },
    { id: "hasPaid", title: "Платежно (виза)" },
    { id: "hasSevis", title: "Платежно (SEVIS)" },
    { id: "hasStudentBook", title: "Студентска книжка" },
    { id: "hasStudentStatus", title: "Справка" },
    { id: "hasJobOffer", title: "Работна оферта (подписана)" },
    { id: "hasConfirmation", title: "Попълнен DS160" },
  ];
};

const getMissingDocuments = (embassy) => {
  const missingDocuments = getApplicationDocuments().filter(
    (doc) => embassy[doc.id] !== true
  );
  const title =
    missingDocuments.length > 0
      ? "Липсващи документи"
      : "Всички документи са налични";
  const remaining = (
    <ul>
      {missingDocuments.map((doc) => (
        <li key={doc.title}>{doc.title}</li>
      ))}
    </ul>
  );
  return [title, remaining];
};

const sortDS160 = (a, b) => {
  if (a.embassy === null) return 1;
  if (b.embassy === null) return -1;
  return ("" + a.embassy?.ds160).localeCompare("" + b.embassy?.ds160);
};

const sortUUID = (a, b) => {
  if (a.embassy === null) return 1;
  if (b.embassy === null) return -1;
  return ("" + a.embassy?.uuid).localeCompare("" + b.embassy?.uuid);
};

const sortInterviewDate = (a, b) => {
  const ae = a.embassy ? moment(a.embassy.interviewDate).unix() : 1;
  const be = b.embassy ? moment(b.embassy.interviewDate).unix() : -1;
  return be - ae;
};

const sortPaymentFee = (a, b) => {
  if (a.embassy === null) return 1;
  if (b.embassy === null) return -1;
  return ("" + a.embassy?.paymentFeeNum).localeCompare(
    "" + b.embassy?.paymentFeeNum
  );
};

const Copyable = (text) => {
  if (!text) return;
  return (
    <Typography.Paragraph copyable style={{ marginBottom: "0px" }}>
      {text}
    </Typography.Paragraph>
  );
};

const hasAlldocuments = (record) => {
  const { embassy = {} } = record;
  return getApplicationDocuments().every((doc) => embassy[doc.id] === true);
};

const getRemainingDocuments = (record) => {
  const { embassy = {} } = record;

  const remaining = [
    "Документи:\n",
    ...getApplicationDocuments()
      .filter((doc) => embassy[doc.id] !== true)
      .map((doc) => doc.title),
  ].join("\n-");

  if (copy(remaining)) {
    message.success("Успешно копирано", 1);
  }
};

const getRowClassName = (record, index) => {
  const { embassy = {} } = record;
  if (embassy.visaApproved) {
    return "ant-table-row-success";
  }
  if (isDatePastNow(embassy.interviewDate) && !embassy.visaApproved) {
    return "ant-table-row-failure";
  }
};

const initialColumns = [
  {
    title: "Студент",
    dataIndex: "fullname",
    render: (text, rec) => {
      const { embassy = {} } = rec;
      const [title, remaining] = getMissingDocuments(embassy);
      return (
        <Fragment>
          <Popover content={remaining} title={title}>
            <BooleanIcon checked={hasAlldocuments(rec)} colored />{" "}
          </Popover>
          {text}
        </Fragment>
      );
    },
    sorter: (a, b) => a.fullname.localeCompare(b.fullname),
  },
  {
    title: "E-mail",
    dataIndex: "email",
    render: Copyable,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    render: Copyable,
  },
  {
    title: "UID",
    dataIndex: "embassy.uuid",
    render: Copyable,
    sorter: sorter([sortUUID, sortInterviewDate]),
  },
  {
    title: "Sponsor",
    dataIndex: "sponsor",
    sorter: (a, b) => ("" + a.sponsor).localeCompare("" + b.sponsor),
  },
  {
    title: "DS160",
    dataIndex: "embassy.ds160",
    render: Copyable,
    sorter: sortDS160,
  },
  {
    title: "DOB",
    dataIndex: "dob",
    render: (text, rec) => Copyable(formatDate(text, "MM/DD/YYYY")),
    visible: false,
  },
  {
    title: "Passport #",
    dataIndex: "passport",
    render: Copyable,
    visible: false,
  },
  {
    title: "Issuance Date",
    dataIndex: "passportValidFrom",
    render: (text, rec) => Copyable(formatDate(text, "MM/DD/YYYY")),
    visible: false,
  },
  {
    title: "Expiration Date",
    dataIndex: "passportValidTill",
    render: (text, rec) => Copyable(formatDate(text, "MM/DD/YYYY")),
    visible: false,
  },
  {
    title: "DS2019",
    dataIndex: "ds2019",
    render: Copyable,
  },
  
  {
    title: "Интервю дата",
    dataIndex: "embassy.interviewDate",
    render: (text) => Copyable(formatDatetimeBasic(text)),
    sorter: sorter([sortInterviewDate, sortUUID]),
    // visible: false,
  },
  {
    title: "Платежно",
    dataIndex: "embassy.paymentFeeNum",
    render: Copyable,
    sorter: sortPaymentFee,
  },
  {
    title: "Action",
    dataIndex: "",
    key: "action",
    render: (text, rec) => (
      <Fragment>
        <Link to={`/embassy/view/${rec._id}`}>
          <Icon type="user" />
        </Link>
        <Divider type="vertical" />
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            getRemainingDocuments(rec);
          }}
        >
          <Icon type="copy" />
        </a>
        <Divider type="vertical" />
        <Link to={`/embassy/edit/${rec._id}`}>
          <Icon type="form" />
        </Link>
      </Fragment>
    ),
    fixed: "right",
  },
];

const fetchReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_START":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        documents: action.payload,
        isLoading: false,
      };
    case "SELECT_SUCCESS":
      return {
        ...state,
        selectedDocuments: action.payload,
        isLoading: false,
      };
    case "FILTER_RESULTS":
      return {
        ...state,
        withoutInterview: action.payload,
      };
    default:
      throw new Error(`Unknown action type ${action.type}`);
  }
};

const fetchDocuments = (query = "", swtSeason, dispatch) => {
  dispatch({ type: "FETCH_START" });
  axios
    .get(`/api/applications?search=${query}&swtSeason=${swtSeason}`)
    .then((res) => {
      dispatch({ type: "FETCH_SUCCESS", payload: res.data });
    });
};

const Embassy = (props) => {
  const { swtSeason } = useSeason();

  const [columns, setColumns] = useState(initialColumns);
  const [
    { withoutInterview, documents, selectedDocuments, isLoading },
    dispatch,
  ] = useReducer(fetchReducer, {
    documents: [],
    selectedDocuments: [],
    isLoading: false,
    withoutInterview: false,
  });
  const [query, setQuery] = useState("");

  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    fetchDocuments(query, swtSeason, dispatch);
  }, [query, swtSeason]);

  const onFilterChange = (value) => {
    setQuery(value);
  };

  const onEdit = (applicationId, values) => {
    const { ds2019 } = values;
    axios
      .put(`/api/applications/${applicationId}`, {
        ds2019,
        embassy: values,
      })
      .then((res) => {
        fetchDocuments(query, swtSeason, dispatch);
        history.push(match.url);
      });
  };

  // rowSelection object indicates the need for row selection
  const rowTransform = (record) => {
    const { embassy = {} } = record;

    return {
      "Last name": record.lastname,
      "Middle name": record.middlename,
      "Given name": record.firstname,
      DOB: formatDate(record.dob, "DD-MMM-YY"),
      "Ppt No": record.passport,
      Gender: record.sex,
      Nationality: "Bulgaria",
      University: record.university?.name,
      "Year of study": record.yearOfStudy,
      "Prior visa": record.priorVisa ? "Y" : "N",
      "Prior refusal": record.priorRefusal ? "Y" : "N",
      Agency: "Happy World",
      Sponsor: record.sponsor,
      Employer: record.employer.name,
      "Employer's address": record.employer.address,
      "Employer's phone No": record.employer.phone,
      "Interview date": formatDate(embassy.interviewDate, "Do of MMMM"),
      "": formatDate(embassy.interviewDate, "HH:mm"),
    };
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const orderedData = orderBy(
        selectedRows,
        (row) => row.embassy && row.embassy.interviewDate,
        ["sponsor"]
      );
      dispatch({
        type: "SELECT_SUCCESS",
        payload: orderedData.map(rowTransform),
      });
    },
    getCheckboxProps: (record) => ({
      disabled: record.embassy
        ? isDatePastNow(record.embassy.interviewDate)
        : false,
      ...record,
    }),
  };

  const selectedStudents = () => (
    <ul>
      {selectedDocuments.map((d) => (
        <li key={`${d["Last name"]}${d["Given name"]}`}>
          {d["Given name"]} {d["Last name"]}
        </li>
      ))}
    </ul>
  );

  return (
    <Fragment>
      <Filter onFilterChange={onFilterChange}>
        {selectedDocuments.length > 0 && (
          <Popover content={selectedStudents()} title="Избрани студенти">
            <Tag color="magenta">{selectedDocuments.length} избрани</Tag>
          </Popover>
        )}
        Само без дата за интервю{" "}
        <Switch
          checked={withoutInterview}
          onChange={(checked) =>
            dispatch({ type: "FILTER_RESULTS", payload: checked })
          }
        />{" "}
        <ToggleColumns columns={columns} setColumns={setColumns} />
        <ExportXLSX csvData={selectedDocuments} fileName="J1_Sofia-Students" />
      </Filter>

      <ToggableTable
        scroll={{ x: "max-content" }}
        rowSelection={rowSelection}
        dataSource={documents.filter((doc) =>
          withoutInterview ? !doc.embassy?.interviewDate : true
        )}
        columns={columns}
        loading={isLoading}
        rowClassName={getRowClassName}
        size={"middle"}
        rowKey="_id"
        pagination={false}
        footer={(currentData) => `Общо: ${currentData.length}`}
      />

      <Route
        exact
        path={`${match.path}/edit/:applicationId`}
        render={(routeProps) => (
          <EditEmbassy
            onClose={() => history.push(match.url)}
            applicationId={routeProps.match.params.applicationId}
            onSubmit={(values) =>
              onEdit(routeProps.match.params.applicationId, values)
            }
          />
        )}
      />

      <Route
        exact
        path={`${match.path}/view/:applicationId`}
        render={(routeProps) => (
          <ViewEmbassy
            applicationId={routeProps.match.params.applicationId}
            onClose={() => history.push(match.url)}
          />
        )}
      />
    </Fragment>
  );
};

export default Embassy;
